/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import dynamic from 'next/dynamic';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import { formatNumber, getLinkProduct } from '@/utils/utils';
import EncodeUrl from '@/utils/encode';

const OwlCarousel = dynamic(() => import('@/componentWebs/Global/OwlCarousel'), {
  ssr: false,
  loading: () => null,
});

@connect(({ webs: { dataAd } }) => ({
  dataAd,
}))
class Index extends Component {
  state = {
    dataIntroduce: [],
    isInternational: '0',
    hideNav: null,
    loading: false,
  };

  componentDidMount() {
    const { isInternational } = this.state;
    this.fetch(isInternational);
    window.addEventListener('resize', this.resize.bind(this), { passive: true });
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  fetch = isInternational => {
    try {
      const { data, dispatch } = this.props;
      this.setState({ loading: true });
      dispatch({
        type: 'webs/fetchComboTour',
        payload: {
          filter: {
            status: '1',
            categoriesId: data.id,
            travelTourIsCombo: true,
            travelTourDestinationLocations: { isInternational },
            categoriesProductsCatalogStatus: '1',
          },
        },
        callback: res => {
          this.setState({ loading: false });
          if (res && res.success) {
            const dataResults =
              res &&
              res.result &&
              res.result.list.length > 0 &&
              res.result.list.map(data => {
                return {
                  id: data.id,
                  travelTourName: data.travelTourName,
                  travelTourImage: data.travelTourImage,
                  travelTourPackageDealPrice: data.travelTourPackageDealPrice,
                  travelTourTime: data.travelTourTime,
                  travelTourOriginProvinces: data.travelTourOriginProvinces,
                };
              }) || [];
            this.setState({
              dataIntroduce: dataResults,
              isInternational,
            });
          } else {
            this.setState({ dataIntroduce: [] });
          }
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleChangeTabs = International => {
    const { isInternational } = this.state;
    if (isInternational !== International) {
      this.fetch(International);
      // this.setState({
      //   isInternational: International,
      // });
    }
  };

  resize() {
    this.setState({ hideNav: window.innerWidth < 1024 });
  }

  handleDetail = item => {
    window.location.href = `/combo/${EncodeUrl(item.travelTourName)}/${item.id}`;
  };

  render() {
    const { dataIntroduce, hideNav, isInternational, loading } = this.state;


    const { data } = this.props;
    // console.log('hideNav: ', data);
    const template =
      data.templateLayouts && data.templateLayouts.templates && data.templateLayouts.templates[0];
    const imageResize =
      template && template.templateLayoutTemplates && template.templateLayoutTemplates.imagesResize;

    return (
      // Tin tức trang chủ
      <React.Fragment>
        {/* Combo giam gia cuc soc mua he  */}
        <section className="combo ">
          <div className="container">
            <div className="combo__wrapper">
              <div className="section-header-products">
                <h3 className="title">
                  <img
                    src="/static/vietnamTickets/images/suitcase.svg"
                    alt="suitcase"
                    className="lazyload"
                    width="auto"
                    height="auto"
                  />
                  <span>{data.name}</span>
                </h3>
                <div />
                <ul className="main-navigation">
                  <li className={`tab ${isInternational === '0' && 'active'}`}>
                    <a onClick={() => this.handleChangeTabs('0')}>
                      <span>
                        <img
                          src="/static/vietnamTickets/images/NoPath - Copy (10).png"
                          alt="Nội địa"
                          className="lazyload"
                        />
                        Nội địa
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="202.068"
                        viewBox="0 0 202.068 51.844"
                      >
                        <path
                          id="Path_4163"
                          data-name="Path 4163"
                          d="M23289.355-14934.914s16.607-.354,22.189-13.742,10.816-37.814,43.607-37.934,70.2,0,70.2,0,26.232-1.732,36.559,18.467,7.742,28.393,29.514,33.324C23492.418-14934.945,23289.355-14934.914,23289.355-14934.914Z"
                          transform="translate(-23289.355 14986.643)"
                          fill="#eb0281"
                        />
                      </svg>
                    </a>
                  </li>
                  <li className={`tab ${isInternational === '1' && 'active'}`}>
                    <a onClick={() => this.handleChangeTabs('1')}>
                      <span>
                        <img
                          src="/static/vietnamTickets/images/earth.svg"
                          alt="Nội địa"
                          className="lazyload"
                        />
                        Quốc tế
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="202.068"
                        viewBox="0 0 202.068 51.844"
                      >
                        <path
                          id="Path_4163"
                          data-name="Path 4163"
                          d="M23289.355-14934.914s16.607-.354,22.189-13.742,10.816-37.814,43.607-37.934,70.2,0,70.2,0,26.232-1.732,36.559,18.467,7.742,28.393,29.514,33.324C23492.418-14934.945,23289.355-14934.914,23289.355-14934.914Z"
                          transform="translate(-23289.355 14986.643)"
                          fill="#eb0281"
                        />
                      </svg>
                    </a>
                  </li>
                  {/* etc. as many tabs as you'd like */}
                </ul>
              </div>
              <Spin spinning={loading}>
                {!hideNav ? (
                  <div className="combo-products">
                    <div className="row">
                      {dataIntroduce && dataIntroduce.length > 0 && (
                        <div className="col-xl-3 col-lg-4">
                          <div className="combo-products__main">
                            <div className="box-product-combo">
                              <a
                                onClick={() => this.handleDetail(dataIntroduce[0])}
                                className="box-product-combo__img box-product-combo__img-main"
                                title={dataIntroduce[0].travelTourName}
                              >
                                <img
                                  src={getResponsiveImage(
                                    dataIntroduce[0].travelTourImage &&
                                    dataIntroduce[0].travelTourImage[0] &&
                                    dataIntroduce[0].travelTourImage[0].file,
                                    imageResize
                                  )}
                                  className="lazyload"
                                  alt={dataIntroduce[0].travelTourName}
                                  title={dataIntroduce[0].travelTourName}
                                />
                                {dataIntroduce[0] &&
                                  dataIntroduce[0].travelTourPackageDealPrice && (
                                    <div className="box-product-combo__price box-product-combo__price-main">
                                      {formatNumber(dataIntroduce[0].travelTourPackageDealPrice)}đ
                                    </div>
                                  )}
                              </a>
                              <div className="box-product-combo__detail">
                                <a
                                  onClick={() => this.handleDetail(dataIntroduce[0])}
                                  title={dataIntroduce[0].travelTourName}
                                  className="box-product-combo__title"
                                >
                                  {dataIntroduce[0].travelTourName}
                                </a>
                                <div className="box-product-combo__plus flex-a-c">
                                  <span className="timeStay">
                                    {dataIntroduce[0].travelTourTime}
                                  </span>
                                  <span className="place">
                                    (
                                    {dataIntroduce[0].travelTourOriginProvinces &&
                                      dataIntroduce[0].travelTourOriginProvinces.provinceName}
                                    )
                                  </span>
                                </div>
                              </div>
                              <div className="product-bombo--btn-wrapper">
                                <a
                                  onClick={() => this.handleDetail(dataIntroduce[0])}
                                  className="btn--product-combo"
                                >
                                  <span>Đặt ngay</span>
                                  <i className="fas fa-long-arrow-alt-right" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-xl-9 col-lg-8">
                        {dataIntroduce && dataIntroduce.length > 0 && Array.isArray(dataIntroduce) && (
                          <OwlCarousel
                            key={`tour2_${isInternational}`}
                            className="section-khachHang-owlCarousel carosel_combo_home"
                            responsive={{
                              0: {
                                items: 1,
                              },
                              425: {
                                items: 2,
                              },
                              768: {
                                items: 2,
                              },
                              1000: {
                                items: 2,
                              },
                              1359: {
                                items: 3,
                              },
                            }}
                            margin={20}
                            dots={false}
                            slideBy={1}
                          >
                            {dataIntroduce.slice(1).map(item => (
                              <div className="box-product-combo" key={item.id}>
                                <a
                                  onClick={() => this.handleDetail(item)}
                                  className="box-product-combo__img"
                                >
                                  <img
                                    src={getResponsiveImage(
                                      item.travelTourImage &&
                                      item.travelTourImage[0] &&
                                      item.travelTourImage[0].file,
                                      imageResize
                                    )}
                                    className="lazyload"
                                    alt={item.travelTourName}
                                    title={item.travelTourName}
                                  />
                                  {item && item.travelTourPackageDealPrice && (
                                    <div className="box-product-combo__price">
                                      {formatNumber(item.travelTourPackageDealPrice)}đ
                                    </div>
                                  )}
                                </a>
                                <div className="box-product-combo__detail">
                                  <a
                                    onClick={() => this.handleDetail(item)}
                                    className="box-product-combo__title"
                                  >
                                    {item.travelTourName}
                                  </a>
                                  <div className="box-product-combo__plus flex-a-c">
                                    <span className="timeStay">{item.travelTourTime}</span>
                                    <span className="place">
                                      (
                                      {item.travelTourOriginProvinces &&
                                        item.travelTourOriginProvinces.provinceName}
                                      )
                                    </span>
                                  </div>
                                </div>
                                <div className="product-bombo--btn-wrapper">
                                  <a
                                    onClick={() => this.handleDetail(item)}
                                    className="btn--product-combo"
                                  >
                                    <i className="fas fa-long-arrow-alt-right" />
                                  </a>
                                </div>
                              </div>
                            ))}
                          </OwlCarousel>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="wrapper-crosel-home">
                      {dataIntroduce && Array.isArray(dataIntroduce) && dataIntroduce.length > 0 && (
                        <OwlCarousel
                          key={`tour_${isInternational}`}
                          className="section-khachHang-owlCarousel carosel_combo_home"
                          responsive={{
                            0: {
                              items: 1,
                            },
                            425: {
                              items: 1,
                            },
                            768: {
                              items: 2,
                            },
                            1000: {
                              items: 3,
                            },
                          }}
                          margin={30}
                          dots={false}
                          slideBy={1}
                        >
                          {dataIntroduce.map(item => (
                            <div className="box-product-combo" key={item.id}>
                              <a
                                onClick={() => this.handleDetail(item)}
                                className="box-product-combo__img"
                              >
                                <img
                                  className="lazyload"
                                  src={getResponsiveImage(
                                    item.travelTourImage &&
                                    item.travelTourImage[0] &&
                                    item.travelTourImage[0].file,
                                    imageResize
                                  )}
                                  alt={item.travelTourName}
                                  title={item.travelTourName}
                                />
                                {item && item.travelTourPackageDealPrice && (
                                  <div className="box-product-combo__price">
                                    {formatNumber(item.travelTourPackageDealPrice)}đ
                                  </div>
                                )}
                              </a>
                              <div className="box-product-combo__detail">
                                <a
                                  onClick={() => this.handleDetail(item)}
                                  className="box-product-combo__title"
                                >
                                  {item.travelTourName}
                                </a>
                                <div className="box-product-combo__plus flex-a-c">
                                  <span className="timeStay">{item.travelTourTime}</span>
                                  <span className="place">
                                    (
                                    {item.travelTourOriginProvinces &&
                                      item.travelTourOriginProvinces.provinceName}
                                    )
                                  </span>
                                </div>
                              </div>
                              <div className="product-bombo--btn-wrapper">
                                <a
                                  onClick={() => this.handleDetail(item)}
                                  className="btn--product-combo"
                                >
                                  <i className="fas fa-long-arrow-alt-right" />
                                </a>
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      )}
                    </div>
                  </>
                )}
              </Spin>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Index;
